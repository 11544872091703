<template>
  <div>
    <el-card>
      <alter-form
        ref="alterForm"
        @handleChange="handleChange"
        @formSubmit="formSubmit"
        :modifyData="modifyData"
        :formData="formData"
        :collapseLength="collapseLength"
      />
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
