import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
import quillText from '@/components/quillText'
import permission from '@/components/permissionList'
// import store from '@/store'
// console.log(store.getters)
// console.log(store.getters.role)
export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.cnName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'service_name',
      name: 'input',
      label: i18n.t('form.setting.customerServiceName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'remote_log_url',
      name: 'image',
      value: [],
      label: i18n.t('form.setting.customerServiceLogo'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1,
        imageName: 'remote_log_url'
      }
    }, {
      span: 8,
      prop: 'telephone',
      name: 'input',
      label: i18n.t('form.setting.customerServicePhoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'remote_background_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.setting.defaultImage'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1,
        imageName: 'remote_background_image_url'
      }
    }, {
      span: 8,
      prop: 'no_of_navigation_rows',
      name: 'select',
      options: [{
        value: 1,
        name: '1'
      }, {
        value: 2,
        name: '2'
      }, {
        value: 3,
        name: '3'
      }],
      value: [],
      label: i18n.t('form.setting.noOfNavigationRows'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'whatsapp_number',
      name: 'input',
      label: i18n.t('form.setting.customerServiceWhatsApp'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    },  {
      span: 8,
      prop: 'google_analytics_tag',
      name: 'input',
      label: i18n.t('form.setting.googleAnalyticsTag'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    },{
      span: 16,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.setting.customerServiceEmail'),
      placeholder: i18n.t('form.setting.emailsTips'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'from_name',
      name: 'input',
      label: i18n.t('form.setting.emailFromName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'official_account_link',
      name: 'input',
      label: i18n.t('form.setting.officialAccountLink'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 12,
      prop: 'official_account_name',
      name: 'input',
      label: i18n.t('form.setting.officialAccountName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'remote_official_account_icon_url',
      name: 'image',
      value: [],
      label: i18n.t('form.setting.officialAccountIcon'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1,
        imageName: 'remote_official_account_icon_url'
      }
    }, {
      span: 8,
      prop: 'remote_slash_sale_moment_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.setting.slashSaleWechatMomentImage'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1,
        imageName: 'remote_slash_sale_moment_image_url'
      }
    }, {
      span: 8,
      prop: 'remote_group_buy_moment_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.setting.groupBuyWechatMomentImage'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1,
        imageName: 'remote_group_buy_moment_image_url'
      }
    }, {
      span: 24,
      prop: 'notice',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.setting.noticeCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        rows: 4
      }
    }, {
      span: 24,
      prop: 'notice_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.setting.NoticeEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        rows: 4
      }
    }]
  }, {
    title: i18n.t('form.setting.invoiceSetting'),
    name: 1,
    createDateItem: [{
      span: 8,
      prop: 'company_name',
      name: 'input',
      label: i18n.t('form.setting.companyName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'co_reg_no',
      name: 'input',
      label: i18n.t('form.setting.companyRegNo'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'gst_reg_no',
      name: 'input',
      label: i18n.t('form.setting.GSTRegNo'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'company_address',
      name: 'input',
      label: i18n.t('form.setting.companyAddress'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'company_postal_code',
      name: 'input',
      label: i18n.t('form.setting.companyPostalCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'invoice_prefix',
      name: 'input',
      label: i18n.t('form.setting.invoicePrefix'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'invoice_language',
      name: 'select',
      options: [{
        value: 'cn',
        name: i18n.t('form.setting.languageCn')
      }, {
        value: 'en',
        name: i18n.t('form.setting.languageEn')
      }],
      value: [],
      label: i18n.t('form.setting.invoiceLanguage'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }]
  }, {
    title: i18n.t('form.setting.paymentSettings'),
    name: 2,
    createDateItem: [{
      span: 8,
      prop: 'pending_payment_window',
      name: 'input',
      type: 'number',
      label: i18n.t('form.setting.pendingPaymentWindow'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }, {
    title: i18n.t('form.setting.buddySettings'),
    name: 3,
    createDateItem: [{
      span: 8,
      prop: 'min_host_withdraw_amount_f',
      name: 'input',
      type: 'number',
      label: i18n.t('form.setting.minimumHostWithdrawAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }, {
    title: i18n.t('form.setting.inventorySettings'),
    name: 4,
    createDateItem: [{
      span: 8,
      prop: 'inventory_provider',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.setting.inventoryProvider'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        client: 'fooyo'
      }
    }]
  }, {
    title: i18n.t('form.setting.loyaltySettings'),
    name: 5,
    createDateItem: [{
      span: 24,
      prop: 'point_system_online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'accumulation_ratio',
      name: 'input',
      type: 'number',
      label: i18n.t('form.setting.accumulationRadio'),
      placeholder: i18n.t('form.setting.accTips'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'redemption_ratio',
      name: 'input',
      type: 'number',
      label: i18n.t('form.setting.redemptionRadio'),
      placeholder: i18n.t('form.setting.redTips'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'minimum_points_per_order',
      name: 'input',
      type: 'number',
      label: i18n.t('form.setting.minimumPointsPerOrder'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'rules',
      name: 'component',
      label: i18n.t('form.setting.rules'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }]
  }, {
    title: i18n.t('form.setting.termsInformation'),
    name: 6,
    createDateItem: [{
      span: 24,
      prop: 'term_and_conditions',
      name: 'component',
      label: i18n.t('form.setting.termsAndConditions'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }, {
      span: 24,
      prop: 'privacy_policy',
      name: 'component',
      label: i18n.t('form.setting.privacyPolicy'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText
      }
    }, {
      span: 24,
      prop: 'game_rule',
      name: 'component',
      label: i18n.t('form.setting.gameRule'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: quillText,
        client: 'fooyo'
      }
    }]
  }, {
    title: i18n.t('form.setting.permissionInfo'),
    name: 7,
    show: false,
    visible: true,
    createDateItem: [
      {
      span: 8,
      prop: 'client_type',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.setting.permissionType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        nextChange: true,
        nextChangeProp: 'client_type',
      }
    }, {
      span: 24,
      prop: 'permissions',
      name: 'component',
      value: [],
      label: i18n.t('form.setting.permissionList'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        name: permission
      }
    }]
  }]
}
