// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getClientsEditDetails, getClientsCollections, putClientsFormData } from '@/services/setting.js'
import { getPermissionList } from '@/services/user.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      permissions: [],
      app_name: process.env.VUE_APP_NAME
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
    computed: {
  // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      'role',
      // ...
    ])
  },
  mounted () {
  },
  methods: {
    async init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      if (this.$store.state.role !== 'client' && this.app_name !== 'combine') {
        const response = await getPermissionList()
        this.permissions = response.data.permissions
        let permissionsType = response.data.permissions.map(item => {
          return {
            name: item.text,
            value: item.name
          }
        })
        this.formData[7].createDateItem[0].options = permissionsType
      }
      if (this.app_name === 'combine') {
        this.formData.pop()
      }
      if (this.app_name !== 'combine') {
        getClientsCollections().then(res => {
          if (res.status === 200) {
            this.formData[4].createDateItem[0].options = res.data.collections.inventory_providers
          }
        })
      }
      getClientsEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.client
          if (this.app_name !== 'combine') {
            if (this.$store.state.role !== 'client') {
              this.modifyData.permissions = this.modifyData.client_permission_attributes && this.modifyData.client_permission_attributes.permissions ? this.modifyData.client_permission_attributes.permissions : []
            }
            let list = []
            if (res.data.client.client_type !== null || res.data.client.client_type !== '' ) {
              list = this.permissions.filter(item => item.name === res.data.client.client_type)
              if (list.length > 0) this.$refs.alterForm.createDate.permissions = this.modifyData.client_type === res.data.client.client_type 
              && this.modifyData.client_permission_attributes && this.modifyData.client_permission_attributes.permissions 
              && this.modifyData.client_permission_attributes.permissions.length > 0 ? this.modifyData.client_permission_attributes.permissions : list[0].permissions
            }
          }
        }
      }).finally(() => {
        if (this.formData[7]) {
          this.formData[7].show = (this.role === 'super_admin')
        }
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop, item, createDate) {
      console.log(data, prop, 'sss')
      let list = []
      if (prop === 'client_type') {
        list = this.permissions.filter(item => item.name === data)
        if (list.length > 0) createDate.permissions = this.modifyData.client_type === data 
        && this.modifyData.client_permission_attributes && this.modifyData.client_permission_attributes.permissions 
        && this.modifyData.client_permission_attributes.permissions.length > 0 ? this.modifyData.client_permission_attributes.permissions : list[0].permissions
        else createDate.permissions = []
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        client: obj
      }
      Object.keys(params.client).forEach(res => {
        if (typeof params.client[res] === 'boolean') {
          if (params.client[res] === true) params.client[res] = '1'
          if (params.client[res] === false) params.client[res] = '0'
        }
      })
      if (this.app_name !== 'combine') {
        if (this.role !== 'client') {
          if (!params.client.client_permission_attributes) {
            params.client.client_permission_attributes = {
              permissions: []
            }
          }
          params.client.client_permission_attributes.permissions = params.client.permissions
        } else {
          delete params.client.client_permission_attributes
          delete params.client.client_type
        }
        params.client.official_account_icon_url =  params.client.remote_official_account_icon_url
        delete params.client.official_account_icon_url
      }

      if (this.app_name === 'combine') {
        delete params.client.client_permission_attributes
        delete params.client.client_type
        delete params.client.game_rule
        delete params.client.inventory_provider
      }
      putClientsFormData(params, this.$route.params.id).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'clientsList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
