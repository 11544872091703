<template>
  <div class="permissionTree">
    <el-tree
      ref="tree"
      :data="innerValue"
      show-checkbox
      node-key="name"
      :default-expand-all="true"
      @check="check"
      @check-change="checkChange"
      :props="defaultProps">
    </el-tree>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    object: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      defaultProps: {
        children: 'children',
        label: 'text'
      },
      innerValue: [],
      initChooseData: []
    }
  },
  watch: {
    value: {
      handler () {
        this.initChooseData = []
        this.innerValue = this.value
        this.deepRead(this.innerValue)
        this.$nextTick(() => {
          if(this.$refs.tree)this.$refs.tree.setCheckedKeys(this.initChooseData, true)
        })
        
      },
      deep: true,
      immediate: true
    }
  },
  created () {
  },
  methods: {
    deepRead (data) {
      data.forEach(res => {
        if (res.children && res.children.length > 0) this.deepRead(res.children)
        else if (res.permission) this.initChooseData.push(res.name)
      })
    },
    handleChange () {
      this.$emit('update:value', this.innerValue)
    },
    checkChange (data, value) {
      data.permission = value
    },
    check (single, obj) {
      obj.halfCheckedNodes.forEach(res => {
        res.permission = true
      })
    }
  }
}
</script>

<style lang="scss" scope>
.permissionTree {
  .el-tree {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}
</style>
